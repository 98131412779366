/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import Rails from 'rails-ujs';
Rails.start();

import 'bootstrap';
import '../styles/application.scss';

document.addEventListener('DOMContentLoaded', () => {
  const reviewForms = document.querySelectorAll('form.new_review');
  for (let form of reviewForms) {
    const shopSelect = form.querySelector('select[name="review[shop_id]"]');
    const newShopFields = form.querySelector('.new_shop_fields');
    if (shopSelect) {
      const updateNewShopFieldsVisibility = () => {
        if (shopSelect.value === '') {
          newShopFields.removeAttribute('hidden');
        } else {
          newShopFields.setAttribute('hidden', 'hidden');
        }
      };
      shopSelect.addEventListener('input', updateNewShopFieldsVisibility);
      updateNewShopFieldsVisibility();
    }
  }
});
